import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { StarIcon } from "../../../assets/icons";
import AuthCard from "../../../Components/AuthCard";
import { Head } from "../../../Components/seo";
import { postAuthRequest } from "../../../services";
import { TRANSACTION } from "../../../services/ApiConstant";

export default function PreferedSubscription() {
  const [messageApi] = message.useMessage();
  const { state } = useLocation();
  const navigate = useNavigate();

  const id = state.id;
  const plans = state.plans;

  const PaymentInfo = () => {
    messageApi.open({
      type: "success",
      content: "Subscribed to plan",
      onClose: () => {
        navigate("/dashboard/home");
      },
    });
  };

  const payment = (id, plan_id) => {
    postAuthRequest(TRANSACTION, { category_id: id, plan_id }).then((res) => {
      res.data.message !== undefined && PaymentInfo();
      window.location.href = res.data.data.authorization_url;
    });
  };

  return (
    <AuthCard
      link={"/exam-type"}
      page="Choose Exam Body"
      step="03"
      title={"Choose your Plan"}
      logo
    // key={}
    >
      <Head title="Pricing Plans" />
      <div className="pricing__box">
        <p className="text-center pricing__info">
          Your card won’t be charged during the free trial, and you can cancel
          anytime.
        </p>

        <div className="pricing__item pricing__item--recommended">
          <div className="pricing__recommendation">
            <StarIcon />
            <span>People’s pick</span>
          </div>
          <div className="pricing__price">
            <h3>
              ₦13,491 <del>₦25,500</del>
            </h3>
            <p>Per Term (Every 4 months)</p>
          </div>
          <button
            className="pricing__btn"
            onClick={() => {
              payment(id, plans[1].id);
            }}
          >
            Start 7 days trial
          </button>
        </div>

        <div className="pricing__item">
          <div className="pricing__price">
            <h3>
              ₦4,497 <del>₦8,500</del>
            </h3>
            <p>Per Month</p>
          </div>
          <button
            onClick={() => {
              payment(id, plans[0].id);
            }}
            className="pricing__btn"
          >
            Start 7 days trial
          </button>
        </div>
      </div>
    </AuthCard>
  );
}
